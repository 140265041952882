export const questions = {
    "General": {
        "en" : [
            {
                Q: "How much does a vehicle inspection cost?",
                A: ["Manual inspections can cost car owners anywhere between $50-$200, while it costs a company $60,000-$70,000 anually for a full-time inspector.", "Inspektlabs AI allows you to achieve the same in just a few dollars."]
            },
            {
                Q: "How long does a car inspection take?",
                A: ["Manual inspections take anywhere between 3-5 days including inspection, analysis, reporting, and travel (in some cases).", "With Inspektlabs AI, the time taken for inspections is cut down significantly, to just a few seconds."]
            },
            {
                Q: "Does Inspektlabs require any additional setup?",
                A: ["No.", "With an easy-to-use plug & play model, and various API integrations, Inspektlabs AI can easily be onboarded into your current workflow.", "Your vehicle inspection reports can be viewed through our web portal, via JSON exports using our API, or via emails sent to your contact address."]
            },
            {
                Q: "Will your product require any training for my staff?",
                A: ["No.", "Inspektlabs AI provides a very intuitive interface making it easy for anyone to learn and operate within no time."]
            },
            {
                Q: "How much does Inspektlabs cost?",
                A: ["Inspektlabs AI uses a per-inspection pricing model that varies based on the volume of monthly inspections.", "Additionally, there is also a one-time integration fee that is charged while onboarding our product."]
            },
            {
                Q: "Where can I see my vehicle damage results?",
                A: ["The vehicle damage results can be viewed either via -", "1. Our web portal which provides you an exclusive dashboard to view reports", "2. On your app using our API integrations", "3. Emails shared to your contact ID", "4. An exclusive white-labelled app built for you", "You can choose the best option based on your convenience during the onboarding process."]
            },
            {
                Q: "Is your product limited to a certain region?",
                A: ["No.", "Inspektlabs AI enables you to conduct remote inspections from any part of the world with subject to your internet connectivity."]
            },
            {
                Q: "Can your product be used to detect small damages?",
                A: ["Inspektlabs' proprietary AI model is trained on 30M+ images and videos of different types of damages and can detect any size of damage on your vehicle."]
            },
            {
                Q: "Do you provide a white-labelled app for my company?",
                A: ["Yes. Inspektlabs' AI model can be used via a white-labelled app built exclusively for your company where we can add or remove sections, make sections mandatory/non mandatory, add wireframes, change logo, add forms/questionnaires, change branding and primary colors."]
            },
            {
                Q: "What does Inspektlab's technology do?",
                A: ["Inspektlabs provides you with an AI model that can detect a range of physical damages on your vehicle either via a fixed camera setup or by using the camera on your smartphone."]
            },
            {
                Q: "Which industries does Inspektlabs serve?",
                A: ["Inspektlabs' AI mdoel is ideal for vehicle inspections in motor insurance, fleet management, car rental/leasing, repair networks, and more automotive industries."]
            },
            {
                Q: "Is there a free trial or demo available?",
                A: ["Yes. We provide you with a free trial for up to 100 inspections. Please contact us for more details."]
            },
            {
                Q: "Can I download reports or export data from Inspektlabs for analysis?",
                A: ["Yes. We support JSON exports and share reports via email that can be downloaded."]
            },
            {
                Q: "How long does it take to setup your product?",
                A: ["Thanks to our intuitive UX and seamless integrations, our product can be up and running for you to use in 24 hours or lesser."]
            },
            {
                Q: "Do I need to use Inspektlabs' App to use your AI tool?",
                A: ["Inspektlabs' AI model can be used by-", "1. Logging into our exclusive web app", "2. Embedding our web app into your existing app", "3. Integrating our web app into your native app", "4. A white-labelled app built exclusively for you", "You can choose either option based on your convenience which can be finalized during the onboarding session"]
            },
            {
                Q: "Is it possible to add custom rules or thresholds for inspections specific to my business?",
                A: ["Yes.", "Inspektlabs AI can be customized to function based on your company's rules and thresholds. We will require you to share some basic information to help us tweak our model based on your rules."]
            }
        ],
        "es": [
            {
                "Q": "¿Cuánto cuesta una inspección de vehículos?",
                "A": ["Las inspecciones manuales pueden costar entre $50 y $200, mientras que a una empresa le cuesta entre $60,000 y $70,000 anuales contratar a un inspector a tiempo completo.", "Inspektlabs AI permite lograr lo mismo por solo unos pocos dólares."]
            },
            {
                "Q": "¿Cuánto tiempo tarda una inspección de vehículos?",
                "A": ["Las inspecciones manuales tardan entre 3 y 5 días, incluyendo inspección, análisis, informes y viajes (en algunos casos).", "Con Inspektlabs AI, el tiempo de inspección se reduce significativamente, a solo unos pocos segundos."]
            },
            {
                "Q": "¿Inspektlabs requiere configuración adicional?",
                "A": ["No.", "Con un modelo fácil de usar y diversas integraciones API, Inspektlabs AI se puede integrar fácilmente en su flujo de trabajo actual.", "Los informes de inspección de vehículos se pueden ver a través de nuestro portal web, mediante exportaciones JSON utilizando nuestra API o por correos electrónicos enviados a su dirección de contacto."]
            },
            {
                "Q": "¿Requiere capacitación para mi personal?",
                "A": ["No.", "Inspektlabs AI ofrece una interfaz muy intuitiva, lo que facilita que cualquier persona aprenda y opere en poco tiempo."]
            },
            {
                "Q": "¿Cuánto cuesta Inspektlabs?",
                "A": ["Inspektlabs AI utiliza un modelo de precios por inspección que varía según el volumen de inspecciones mensuales.", "Además, se cobra una tarifa de integración única durante la incorporación de nuestro producto."]
            },
            {
                "Q": "¿Dónde puedo ver los resultados de los daños del vehículo?",
                "A": ["Los resultados de daños se pueden ver a través de:", "1. Nuestro portal web con un tablero exclusivo", "2. En su aplicación mediante nuestras integraciones API", "3. Correos electrónicos enviados a su ID de contacto", "4. Una aplicación personalizada creada exclusivamente para usted", "Puede elegir la mejor opción durante el proceso de incorporación."]
            },
            {
                "Q": "¿Está limitado a una región específica?",
                "A": ["No.", "Inspektlabs AI permite realizar inspecciones remotas desde cualquier parte del mundo, sujeto a la conectividad a Internet."]
            },
            {
                "Q": "¿Se pueden detectar daños pequeños?",
                "A": ["El modelo de IA de Inspektlabs, entrenado con más de 30 millones de imágenes y videos, puede detectar cualquier tamaño de daño en su vehículo."]
            },
            {
                "Q": "¿Ofrecen una aplicación personalizada para mi empresa?",
                "A": ["Sí. Inspektlabs AI puede usarse a través de una aplicación personalizada diseñada exclusivamente para su empresa, donde podemos agregar o eliminar secciones, cambiar logos, formularios, colores, y más."]
            },
            {
                "Q": "¿Qué hace la tecnología de Inspektlabs?",
                "A": ["Inspektlabs ofrece un modelo de IA que puede detectar una variedad de daños físicos en su vehículo, ya sea mediante una cámara fija o utilizando la cámara de su smartphone."]
            },
            {
                "Q": "¿A qué industrias sirve Inspektlabs?",
                "A": ["El modelo de Inspektlabs es ideal para inspecciones de vehículos en seguros de motor, gestión de flotas, alquiler de coches, redes de reparación y más industrias automotrices."]
            },
            {
                "Q": "¿Hay disponible una prueba gratuita o demostración?",
                "A": ["Sí. Ofrecemos una prueba gratuita para hasta 100 inspecciones. Por favor, contáctenos para más detalles."]
            },
            {
                "Q": "¿Puedo descargar informes o exportar datos para análisis?",
                "A": ["Sí. Soportamos exportaciones JSON y compartimos informes por correo electrónico que se pueden descargar."]
            },
            {
                "Q": "¿Cuánto tiempo se tarda en configurar su producto?",
                "A": ["Gracias a nuestra UX intuitiva e integraciones sencillas, nuestro producto puede estar listo para usar en menos de 24 horas."]
            },
            {
                "Q": "¿Necesito usar la app de Inspektlabs para usar su IA?",
                "A": ["El modelo de Inspektlabs AI puede usarse mediante:", "1. Iniciar sesión en nuestra app web exclusiva", "2. Incrustar nuestra app web en su aplicación existente", "3. Integrar nuestra app web en su aplicación nativa", "4. Una aplicación personalizada creada exclusivamente para usted", "Puede elegir la opción más conveniente durante la incorporación."]
            },
            {
                "Q": "¿Es posible agregar reglas personalizadas o umbrales específicos para mi negocio?",
                "A": ["Sí.", "Inspektlabs AI se puede personalizar según las reglas y umbrales de su empresa. Solo necesitamos información básica para ajustar nuestro modelo."]
            }
        ],
        "pt": [
            {
                "Q": "Quanto custa uma inspeção de veículos?",
                "A": ["As inspeções manuais podem custar entre $50 e $200, enquanto para uma empresa custa entre $60.000 e $70.000 anuais para um inspetor em tempo integral.", "Com Inspektlabs AI, você pode realizar o mesmo por apenas alguns dólares."]
            },
            {
                "Q": "Quanto tempo leva uma inspeção de veículos?",
                "A": ["Inspeções manuais levam entre 3 e 5 dias, incluindo inspeção, análise, relatórios e deslocamento (em alguns casos).", "Com Inspektlabs AI, o tempo é reduzido significativamente para apenas alguns segundos."]
            },
            {
                "Q": "Inspektlabs requer configuração adicional?",
                "A": ["Não.", "Com um modelo fácil de usar e diversas integrações API, Inspektlabs AI pode ser integrado facilmente ao seu fluxo de trabalho atual.", "Os relatórios podem ser acessados pelo portal web, exportações JSON ou emails enviados para o endereço cadastrado."]
            },
            {
                "Q": "É necessário treinamento para minha equipe?",
                "A": ["Não.", "Inspektlabs AI oferece uma interface intuitiva que torna fácil para qualquer pessoa aprender e operar rapidamente."]
            },
            {
                "Q": "Quanto custa o Inspektlabs?",
                "A": ["Inspektlabs AI usa um modelo de preços por inspeção que varia de acordo com o volume mensal de inspeções.", "Há também uma taxa de integração única cobrada durante a implementação."]
            },
            {
                "Q": "Onde posso ver os resultados dos danos ao veículo?",
                "A": ["Os resultados podem ser acessados através de:", "1. Nosso portal web com um painel exclusivo", "2. Sua aplicação usando nossas integrações API", "3. Emails enviados para o ID de contato", "4. Um aplicativo personalizado desenvolvido para sua empresa"]
            },
            {
                "Q": "O produto é limitado a uma região específica?",
                "A": ["Não.", "Inspektlabs AI permite realizar inspeções remotas de qualquer parte do mundo, dependendo da conectividade à internet."]
            },
            {
                "Q": "É possível detectar danos pequenos?",
                "A": ["O modelo de IA do Inspektlabs, treinado com mais de 30M imagens e vídeos, pode detectar qualquer tamanho de dano."]
            },
            {
                "Q": "Vocês fornecem um aplicativo personalizado para minha empresa?",
                "A": ["Sim. Inspektlabs AI pode ser usado por meio de um aplicativo personalizado que pode incluir mudanças de branding, logos, formulários e mais."]
            },
            {
                "Q": "O que a tecnologia do Inspektlabs faz?",
                "A": ["Inspektlabs fornece um modelo de IA que pode detectar uma variedade de danos físicos no veículo usando uma câmera fixa ou a câmera de um smartphone."]
            },
            {
                "Q": "Quais indústrias o Inspektlabs atende?",
                "A": ["O modelo de IA do Inspektlabs é ideal para inspeções em seguros automotivos, gestão de frotas, locação de veículos, redes de reparo e mais."]
            },
            {
                "Q": "Há um teste ou demonstração gratuita disponível?",
                "A": ["Sim. Oferecemos um teste gratuito para até 100 inspeções. Entre em contato para mais detalhes."]
            },
            {
                "Q": "Posso baixar relatórios ou exportar dados para análise?",
                "A": ["Sim. Oferecemos suporte para exportações JSON e relatórios via email que podem ser baixados."]
            },
            {
                "Q": "Quanto tempo leva para configurar o produto?",
                "A": ["Graças à nossa UX intuitiva e integrações fáceis, o produto pode ser configurado e pronto para uso em menos de 24 horas."]
            },
            {
                "Q": "Preciso usar o app do Inspektlabs para utilizar a IA?",
                "A": ["O modelo Inspektlabs AI pode ser usado por:", "1. Acessar nosso app web exclusivo", "2. Integrar o app à sua aplicação existente", "3. Incorporar nosso app em uma aplicação nativa", "4. Um aplicativo personalizado desenvolvido para sua empresa"]
            },
            {
                "Q": "É possível adicionar regras ou limites personalizados para minha empresa?",
                "A": ["Sim.", "O modelo pode ser ajustado com base nas regras e limites específicos do seu negócio. Precisamos de informações básicas para configurar."]
            }
        ]
    },
    "Damage Detection": {
        "en": [
            {
                Q: "Do you have a support desk to fix issues?",
                A: ["Yes. We have a 24x7 support desk to address and fix any issues you are facing with our product. You can raise a JIRA ticket or directly email us to flag your issues."]
            },
            {
                Q: "Can Inspektlabs integrate with my existing systems?",
                A: ["Yes. We provide a variety of API integrations to seamlessly integrate into your existing system and workflow"]
            },
            {
                Q: "What type of damages does Inspektlabs cover?",
                A: ["Inspektlabs' proprietary AI is trained to detect all types of physical damage from dents, scratches, tears, glass cracks, hail damages and other miscellaneous damages.", "However, internal damage (like engine, braking, and other mechanical damage) might require some manual intervention for a more detailed report."]
            },
            {
                Q: "What type of vehicles can I use your product on?",
                A: ["Inspektlabs' proprietary AI model is trained on 30M+ images and videos of different vehicle damages, and very easily detect damage on any vehicle ranging from hatchback, sedan, SUV, Vans, pickup truck, truck, bus, motorbike.", "However, the model is not recommended for farm vehicles such as tractors, combines etc."]
            },
            {
                Q: "How accurate is your solution for damage detection?",
                A: ["Inspektlabs AI has shown 90%-95% accuracy in damage detection excluding corner cases that occur rarely."]
            },
            {
                Q: "Does the platform offer a grading or scoring system for overall vehicle condition?",
                A: ["Yes. We can create customized reports based on your grading system upon request e.g. NAMA, Autograde, AutoIMS"]
            },
            {
                Q: "What type of parts does Inspektlabs cover?",
                A: ["Inspektlabs' proprietary AI is trained to detect damages on 163 different parts including interior (eg: seats) and exterior (eg: headlights) parts.", "You can read more details about how we facilitate this on our blog."]
            }
        ],
        "es": [
            {
                "Q": "¿Tienen un servicio de soporte para resolver problemas?",
                "A": ["Sí. Contamos con un servicio de soporte disponible las 24 horas, los 7 días de la semana, para atender y solucionar cualquier problema que tenga con nuestro producto. Puede generar un ticket en JIRA o enviarnos un correo electrónico directamente para reportar sus problemas."]
            },
            {
                "Q": "¿Puede Inspektlabs integrarse con mis sistemas existentes?",
                "A": ["Sí. Ofrecemos una variedad de integraciones API para integrarnos perfectamente en su sistema y flujo de trabajo existentes."]
            },
            {
                "Q": "¿Qué tipo de daños cubre Inspektlabs?",
                "A": ["La IA propietaria de Inspektlabs está entrenada para detectar todo tipo de daños físicos como abolladuras, arañazos, rasgaduras, grietas en vidrios, daños por granizo y otros daños diversos.", "Sin embargo, los daños internos (como en el motor, frenos y otros problemas mecánicos) pueden requerir intervención manual para un informe más detallado."]
            },
            {
                "Q": "¿En qué tipo de vehículos puedo usar su producto?",
                "A": ["El modelo de IA de Inspektlabs está entrenado con más de 30 millones de imágenes y videos de daños en vehículos, y puede detectar daños fácilmente en cualquier vehículo, como hatchbacks, sedanes, SUVs, furgonetas, camionetas, camiones, autobuses y motocicletas.", "Sin embargo, el modelo no se recomienda para vehículos agrícolas como tractores, cosechadoras, etc."]
            },
            {
                "Q": "¿Qué tan precisa es su solución para la detección de daños?",
                "A": ["La IA de Inspektlabs ha demostrado una precisión del 90%-95% en la detección de daños, excluyendo casos excepcionales que ocurren raramente."]
            },
            {
                "Q": "¿La plataforma ofrece un sistema de calificación o puntuación para la condición general del vehículo?",
                "A": ["Sí. Podemos crear informes personalizados basados en su sistema de calificación bajo solicitud, por ejemplo, NAMA, Autograde, AutoIMS."]
            },
            {
                "Q": "¿Qué tipo de partes cubre Inspektlabs?",
                "A": ["La IA propietaria de Inspektlabs está entrenada para detectar daños en 163 partes diferentes, incluyendo partes interiores (por ejemplo: asientos) y exteriores (por ejemplo: faros).", "Puede leer más detalles sobre cómo facilitamos esto en nuestro blog."]
            }
        ],
        "pt": [
            {
                "Q": "Vocês possuem um suporte técnico para resolver problemas?",
                "A": ["Sim. Temos uma equipe de suporte disponível 24 horas por dia, 7 dias por semana, para atender e solucionar quaisquer problemas que você possa enfrentar com nosso produto. Você pode abrir um ticket no JIRA ou nos enviar um e-mail diretamente para relatar seus problemas."]
            },
            {
                "Q": "O Inspektlabs pode ser integrado aos meus sistemas existentes?",
                "A": ["Sim. Oferecemos diversas integrações de API para integrar perfeitamente ao seu sistema e fluxo de trabalho existentes."]
            },
            {
                "Q": "Que tipos de danos o Inspektlabs cobre?",
                "A": ["A IA proprietária do Inspektlabs foi treinada para detectar todos os tipos de danos físicos, como amassados, arranhões, rasgos, rachaduras em vidros, danos causados por granizo e outros danos diversos.", "No entanto, danos internos (como no motor, freios e outros problemas mecânicos) podem exigir intervenção manual para um relatório mais detalhado."]
            },
            {
                "Q": "Em que tipo de veículos posso usar seu produto?",
                "A": ["O modelo de IA do Inspektlabs foi treinado com mais de 30 milhões de imagens e vídeos de danos em veículos e pode detectar facilmente danos em qualquer veículo, como hatchbacks, sedãs, SUVs, vans, caminhonetes, caminhões, ônibus e motocicletas.", "No entanto, o modelo não é recomendado para veículos agrícolas, como tratores, colheitadeiras, etc."]
            },
            {
                "Q": "Quão precisa é a solução de detecção de danos?",
                "A": ["A IA do Inspektlabs demonstrou uma precisão de 90%-95% na detecção de danos, excluindo casos raros."]
            },
            {
                "Q": "A plataforma oferece um sistema de pontuação para a condição geral do veículo?",
                "A": ["Sim. Podemos criar relatórios personalizados com base no seu sistema de pontuação mediante solicitação, por exemplo, NAMA, Autograde, AutoIMS."]
            },
            {
                "Q": "Que tipo de partes o Inspektlabs cobre?",
                "A": ["A IA proprietária do Inspektlabs foi treinada para detectar danos em 163 partes diferentes, incluindo partes internas (ex: assentos) e externas (ex: faróis).", "Você pode ler mais detalhes sobre como facilitamos isso em nosso blog."]
            }
        ]
    },
    "Workflow": {
        "en": [
            {
                Q: "How long does it take to setup your product?",
                A: ["Thanks to our intuitive UX and seamless integrations, our product can be up and running for you to use in 24 hours or lesser."]
            },
            {
                Q: "Do I need to use Inspektlabs' App to use your AI tool?",
                A: ["Inspektlabs' AI model can be used by-", "1. Logging into our exclusive web app", "2. Embedding our web app into your existing app", "3. Integrating our web app into your native app", "4. A white-labelled app built exclusively for you", "You can choose either option based on your convenience which can be finalized during the onboarding session"]
            },
            {
                Q: "Is it possible to add custom rules or thresholds for inspections specific to my business?",
                A: ["Yes.", "Inspektlabs AI can be customized to function based on your company's rules and thresholds. We will require you to share some basic information to help us tweak our model based on your rules."]
            },
            {
                Q: "Can Inspektlabs integrate with my existing systems?",
                A: ["Yes. We provide a variety of API integrations to seamlessly integrate into your existing system and workflow"]
            },
            {
                Q: "What do you do with my vehicle data?",
                A: ["Inspektlabs stores your data on local AWS servers (based on your region) that is later used for further enhancing our AI model. However, we ensure to mask PII (Personal identifiable information) such as license plates, faces etc. to ensure personal information safety.", "Our product is both ISO 27001 certified and GDPR compliant, so you can be sure about the safety of your data.", "Additionally, we can also setup scheduled data purges to auto-delete information based on your request"]
            },
            {
                Q: "What systems does Inspektlabs integrate with (e.g., CRM, claims management, fleet software)?",
                A: ["Inspektlabs provides API, SDK, and webhook based integrations for various platforms."]
            },
        ],
        "es": [
            {
                "Q": "¿Cuánto tiempo se tarda en configurar su producto?",
                "A": ["Gracias a nuestra interfaz de usuario intuitiva y las integraciones sin problemas, nuestro producto puede estar en funcionamiento y listo para usar en 24 horas o menos."]
            },
            {
                "Q": "¿Necesito usar la aplicación de Inspektlabs para utilizar su herramienta de IA?",
                "A": ["El modelo de IA de Inspektlabs se puede utilizar de las siguientes maneras:", "1. Iniciando sesión en nuestra aplicación web exclusiva", "2. Integrando nuestra aplicación web en su aplicación existente", "3. Integrando nuestra aplicación web en su aplicación nativa", "4. Una aplicación con marca blanca construida exclusivamente para usted", "Puede elegir cualquiera de estas opciones según su conveniencia, la cual se definirá durante la sesión de incorporación."]
            },
            {
                "Q": "¿Es posible agregar reglas o umbrales personalizados para inspecciones específicas de mi negocio?",
                "A": ["Sí.", "La IA de Inspektlabs se puede personalizar para funcionar según las reglas y umbrales de su empresa. Necesitaremos que nos proporcione algo de información básica para ayudarnos a ajustar nuestro modelo según sus reglas."]
            },
            {
                "Q": "¿Puede Inspektlabs integrarse con mis sistemas existentes?",
                "A": ["Sí. Ofrecemos una variedad de integraciones API para integrarnos sin problemas a su sistema y flujo de trabajo existentes."]
            },
            {
                "Q": "¿Qué hacen con los datos de mi vehículo?",
                "A": ["Inspektlabs almacena sus datos en servidores locales de AWS (según su región), que luego se utilizan para mejorar nuestro modelo de IA. Sin embargo, nos aseguramos de enmascarar la PII (información personal identificable), como matrículas, rostros, etc., para garantizar la seguridad de la información personal.", "Nuestro producto está certificado por ISO 27001 y cumple con el GDPR, por lo que puede estar seguro sobre la seguridad de sus datos.", "Además, también podemos configurar purgas de datos programadas para eliminar automáticamente la información según su solicitud."]
            },
            {
                "Q": "¿Con qué sistemas se integra Inspektlabs (por ejemplo, CRM, gestión de reclamaciones, software de flotas)?",
                "A": ["Inspektlabs ofrece integraciones basadas en API, SDK y webhook para diversas plataformas."]
            }
        ],
        "pt": [
            {
                "Q": "Quanto tempo leva para configurar seu produto?",
                "A": ["Graças à nossa interface intuitiva e integrações sem problemas, nosso produto pode estar em funcionamento e pronto para uso em 24 horas ou menos."]
            },
            {
                "Q": "Preciso usar o aplicativo da Inspektlabs para utilizar sua ferramenta de IA?",
                "A": ["O modelo de IA da Inspektlabs pode ser usado de várias formas:", "1. Fazendo login em nosso aplicativo web exclusivo", "2. Integrando nosso aplicativo web em seu aplicativo existente", "3. Integrando nosso aplicativo web em seu aplicativo nativo", "4. Um aplicativo com marca branca desenvolvido exclusivamente para você", "Você pode escolher qualquer uma dessas opções de acordo com sua conveniência, que será definida durante a sessão de integração."]
            },
            {
                "Q": "É possível adicionar regras ou limites personalizados para inspeções específicas do meu negócio?",
                "A": ["Sim.", "A IA da Inspektlabs pode ser personalizada para funcionar com base nas regras e limites de sua empresa. Precisaremos que você nos forneça algumas informações básicas para nos ajudar a ajustar nosso modelo conforme suas regras."]
            },
            {
                "Q": "O Inspektlabs pode ser integrado aos meus sistemas existentes?",
                "A": ["Sim. Oferecemos uma variedade de integrações de API para integrar perfeitamente ao seu sistema e fluxo de trabalho existentes."]
            },
            {
                "Q": "O que vocês fazem com os dados do meu veículo?",
                "A": ["O Inspektlabs armazena seus dados em servidores locais da AWS (de acordo com sua região), que são usados para aprimorar nosso modelo de IA. No entanto, garantimos mascarar PII (informações pessoais identificáveis), como placas de veículos, rostos, etc., para garantir a segurança das informações pessoais.", "Nosso produto é certificado pela ISO 27001 e compatível com o GDPR, então você pode ter certeza sobre a segurança de seus dados.", "Além disso, também podemos configurar purgas de dados programadas para excluir automaticamente as informações conforme sua solicitação."]
            },
            {
                "Q": "Com quais sistemas o Inspektlabs se integra (por exemplo, CRM, gestão de reclamações, software de frotas)?",
                "A": ["O Inspektlabs oferece integrações baseadas em API, SDK e webhook para várias plataformas."]
            }
        ]
    },
    "Our Core Technology": {
        "en": [
            {
                Q: "Which cloud platforms does Inspektlabs use to host its services?",
                A: ["Inspektlabs currently uses local AWS servers to store data and host its services."]
            },
            {
                Q: "What kind of camera setups are required for automated inspections?",
                A: ["Inspektlabs' AI can be used either via a fixed camera setup or via the cameras on your smartphone"]
            },
            {
                Q: "How does Inspektlabs ensure data security and compliance with global regulations?",
                A: ["Inspektlabs is ISO 27001 certified and GDPR compliant to guarantee data secruity.", "We also have setup checks to mask PII (personal identifiable information) and can setup scheduled purges for your data upon request."]
            },
            {
                Q: "What kind of models does your damage detection solution use?",
                A: ["Inspektlabs uses a proprietary algorithm that is trained to detect damages on 162 vehicle parts using computer vision.", "We use an ensemble of object detection and segmentation model based on different use-cases to detect vehicle damage. You can read more about it on our blog."]
            },
        ],
        "es": [
            {
                "Q": "¿Qué plataformas en la nube utiliza Inspektlabs para alojar sus servicios?",
                "A": ["Inspektlabs actualmente utiliza servidores locales de AWS para almacenar datos y alojar sus servicios."]
            },
            {
                "Q": "¿Qué tipo de configuraciones de cámara se requieren para las inspecciones automatizadas?",
                "A": ["La IA de Inspektlabs se puede usar a través de una configuración de cámara fija o mediante las cámaras de su smartphone."]
            },
            {
                "Q": "¿Cómo garantiza Inspektlabs la seguridad de los datos y el cumplimiento de las regulaciones globales?",
                "A": ["Inspektlabs está certificado por la ISO 27001 y cumple con el GDPR para garantizar la seguridad de los datos.", "También hemos configurado verificaciones para enmascarar la PII (información personal identificable) y podemos configurar purgas programadas para sus datos bajo solicitud."]
            },
            {
                "Q": "¿Qué tipo de modelos utiliza su solución de detección de daños?",
                "A": ["Inspektlabs utiliza un algoritmo patentado que está entrenado para detectar daños en 162 partes de vehículos utilizando visión por computadora.", "Utilizamos un conjunto de modelos de detección de objetos y segmentación basados en diferentes casos de uso para detectar daños en vehículos. Puede leer más sobre esto en nuestro blog."]
            }
        ],
        "pt": [
            {
                "Q": "Quais plataformas de nuvem a Inspektlabs usa para hospedar seus serviços?",
                "A": ["Atualmente, a Inspektlabs utiliza servidores locais da AWS para armazenar dados e hospedar seus serviços."]
            },
            {
                "Q": "Que tipo de configurações de câmera são necessárias para inspeções automatizadas?",
                "A": ["A IA da Inspektlabs pode ser usada por meio de uma configuração de câmera fixa ou pelas câmeras do seu smartphone."]
            },
            {
                "Q": "Como a Inspektlabs garante a segurança dos dados e o cumprimento das regulamentações globais?",
                "A": ["A Inspektlabs é certificada pela ISO 27001 e está em conformidade com o GDPR para garantir a segurança dos dados.", "Também configuramos verificações para mascarar a PII (informações pessoais identificáveis) e podemos configurar purgas programadas para seus dados, conforme solicitado."]
            },
            {
                "Q": "Que tipo de modelos sua solução de detecção de danos utiliza?",
                "A": ["A Inspektlabs usa um algoritmo proprietário que é treinado para detectar danos em 162 partes de veículos usando visão computacional.", "Usamos um conjunto de modelos de detecção de objetos e segmentação com base em diferentes casos de uso para detectar danos em veículos. Você pode ler mais sobre isso em nosso blog."]
            }
        ]
    },
    "Claim Assessment": {
        "en": [
            {
                Q: "Do you have a support desk to fix issues?",
                A: ["Yes. We have a 24x7 support desk to address and fix any issues you are facing with our product. You can raise a JIRA ticket or directly email us to flag your issues."]
            },
            {
                Q: "Can Inspektlabs integrate with my existing systems?",
                A: ["Yes. We provide a variety of API integrations to seamlessly integrate into your existing system and workflow."]
            },
            {
                Q: "Can Inspektlabs provide repair cost estimates?",
                A: ["Yes. Our tool allows you to fetch repair estimates from various estimatics providers based on the extent of damage."]
            },
            {
                Q: "Can I use your product to conduct pre-inspections?",
                A: ["Yes", "Inspektlabs AI can help automate your pre-inspection process and easily determine the ideal premium to charge based on the vehicle's condition.", "The algorithm also detects instances of damage cover-up using stickers, vehicle switching etc. to avoid fraudulent claims in the future."]
            },
            {
                Q: "How do you calibrate repair methodologies for different markets?",
                A: ["We will collect information from you during the onboarding phase using a survey methodology.", "This information will later be used to calibrate repair methodologies that fit your process.", "For example: A sharp hole in a car's front bumper is usually repaired in the USA, while it is to be replaced in Germany. We can use this information to calibrate our product to your country's market and company preferences."]
            },
            {
                Q: "Can I use your product to conduct FNOL?",
                A: ["Yes.", "All you have to do is share a link with your customer who can upload photos and videos via our Web app. These photos are then analysed using Inspektlabs' proprietary AI and a detailed report covering total loss vs. repairable loss decisions is shared with you"]
            }
        ],
        "es": [
            {
                "Q": "¿Tienen un servicio de soporte para solucionar problemas?",
                "A": ["Sí. Contamos con un servicio de soporte 24x7 para abordar y solucionar cualquier problema que esté enfrentando con nuestro producto. Puede crear un ticket en JIRA o enviarnos un correo electrónico directamente para señalar sus problemas."]
            },
            {
                "Q": "¿Puede Inspektlabs integrarse con mis sistemas existentes?",
                "A": ["Sí. Ofrecemos una variedad de integraciones API para integrarnos sin problemas en su sistema y flujo de trabajo existentes."]
            },
            {
                "Q": "¿Puede Inspektlabs proporcionar estimaciones de costos de reparación?",
                "A": ["Sí. Nuestra herramienta le permite obtener estimaciones de reparación de varios proveedores de estimación según la extensión del daño."]
            },
            {
                "Q": "¿Puedo usar su producto para realizar pre-inspecciones?",
                "A": ["Sí.", "La IA de Inspektlabs puede ayudar a automatizar su proceso de pre-inspección y determinar fácilmente la prima ideal a cobrar según el estado del vehículo.", "El algoritmo también detecta casos de encubrimiento de daños mediante pegatinas, intercambio de vehículos, etc., para evitar reclamaciones fraudulentas en el futuro."]
            },
            {
                "Q": "¿Cómo calibran las metodologías de reparación para diferentes mercados?",
                "A": ["Recopilaremos información de su parte durante la fase de incorporación mediante una metodología de encuesta.", "Esta información se utilizará posteriormente para calibrar las metodologías de reparación que se adapten a su proceso.", "Por ejemplo: Un agujero grande en el parachoques delantero de un automóvil generalmente se repara en EE. UU., mientras que en Alemania se reemplaza. Podemos usar esta información para calibrar nuestro producto según el mercado de su país y las preferencias de su empresa."]
            },
            {
                "Q": "¿Puedo usar su producto para realizar FNOL?",
                "A": ["Sí.", "Todo lo que tiene que hacer es compartir un enlace con su cliente, quien puede cargar fotos y videos a través de nuestra aplicación web. Estas fotos se analizan utilizando la IA propietaria de Inspektlabs y se le envía un informe detallado que cubre decisiones de pérdida total frente a pérdida reparable."]
            }
        ],
        "pt": [
            {
                "Q": "Você tem uma central de suporte para resolver problemas?",
                "A": ["Sim. Temos uma central de suporte 24x7 para resolver qualquer problema que você esteja enfrentando com nosso produto. Você pode abrir um ticket no JIRA ou nos enviar um e-mail diretamente para sinalizar seus problemas."]
            },
            {
                "Q": "A Inspektlabs pode se integrar com meus sistemas existentes?",
                "A": ["Sim. Oferecemos uma variedade de integrações API para integrar-se perfeitamente ao seu sistema e fluxo de trabalho existentes."]
            },
            {
                "Q": "A Inspektlabs pode fornecer estimativas de custos de reparo?",
                "A": ["Sim. Nossa ferramenta permite que você obtenha estimativas de reparo de vários provedores de estimativas com base na extensão do dano."]
            },
            {
                "Q": "Posso usar seu produto para realizar pré-inspeções?",
                "A": ["Sim.", "A IA da Inspektlabs pode ajudar a automatizar seu processo de pré-inspeção e determinar facilmente o prêmio ideal a ser cobrado com base no estado do veículo.", "O algoritmo também detecta casos de encobrimento de danos, como adesivos, troca de veículos etc., para evitar reivindicações fraudulentas no futuro."]
            },
            {
                "Q": "Como calibram as metodologias de reparo para diferentes mercados?",
                "A": ["Coletaremos informações suas durante a fase de integração por meio de uma metodologia de pesquisa.", "Essas informações serão usadas posteriormente para calibrar as metodologias de reparo que se ajustem ao seu processo.", "Por exemplo: Um buraco grande no para-choque dianteiro de um carro geralmente é reparado nos EUA, enquanto é substituído na Alemanha. Podemos usar essas informações para calibrar nosso produto para o mercado do seu país e as preferências da sua empresa."]
            },
            {
                "Q": "Posso usar seu produto para realizar FNOL?",
                "A": ["Sim.", "Tudo o que você precisa fazer é compartilhar um link com seu cliente, que pode enviar fotos e vídeos por meio de nosso aplicativo web. Essas fotos são analisadas usando a IA proprietária da Inspektlabs e um relatório detalhado cobrindo decisões de perda total versus perda reparável é enviado para você."]
            }
        ]
    },
    "Photo and Video Capture": {
        "en": [
            {
                Q: "Do you have a support desk to fix issues?",
                A: ["Yes. We have a 24x7 support desk to address and fix any issues you are facing with our product. You can raise a JIRA ticket or directly email us to flag your issues."]
            },
            {
                Q: "Can Inspektlabs integrate with my existing systems?",
                A: ["Yes. We provide a variety of API integrations to seamlessly integrate into your existing system and workflow."]
            },
            {
                Q: "What type of parts does Inspektlabs cover?",
                A: ["Inspektlabs' proprietary AI is trained to detect damages on 163 different parts including interior (eg: seats) and exterior (eg: headlights) parts.", "You can read more details about how we facilitate this on our blog."]
            },
            {
                Q: "Who can use your product for vehicle inspections?",
                A: ["Wit Inspektlabs AI, you can enable both your staff as well as your customers to conduct vehicle inspections remotely.", "Our product can be accessed through a web app/native app/SDK or by using an API integrated with your existing app."]
            },
            {
                Q: "What are the integrations provided by Inspektlabs?",
                A: ["Inspektlabs' seamless API integrations can connect directly into your workflows where we can send a JSON payload to a webhook provided by you.", "This payload typically contains URLs of the vehicles and damage coordinates."]
            },
            {
                Q: "Does Inspektlabs support both photos and videos for inspections?",
                A: ["Yes. Inspektlabs' proprietary AI is trained to detect damage using both photos and videos of vehicle damage."]
            },
            {
                Q: "How does Inspektlabs handle VIN, license plates, and odometer readings?",
                A: ["Inspektlabs AI is trained to identify vehicle identification information like VIN and license plates which is later verified to confirm vehicle ownership.", "Capturing the odometer reading is also a mandatory step in the inspection workflow while using Inspektlabs AI (Read more)"]
            }
        ],
        "es": [
            {
                "Q": "¿Tienen un servicio de soporte para solucionar problemas?",
                "A": ["Sí. Contamos con un servicio de soporte 24x7 para abordar y solucionar cualquier problema que esté enfrentando con nuestro producto. Puede crear un ticket en JIRA o enviarnos un correo electrónico directamente para señalar sus problemas."]
            },
            {
                "Q": "¿Puede Inspektlabs integrarse con mis sistemas existentes?",
                "A": ["Sí. Ofrecemos una variedad de integraciones API para integrarnos sin problemas en su sistema y flujo de trabajo existentes."]
            },
            {
                "Q": "¿Qué tipo de partes cubre Inspektlabs?",
                "A": ["La IA propietaria de Inspektlabs está entrenada para detectar daños en 163 partes diferentes, incluyendo partes interiores (por ejemplo: asientos) y exteriores (por ejemplo: faros).", "Puede leer más detalles sobre cómo facilitamos esto en nuestro blog."]
            },
            {
                "Q": "¿Quién puede usar su producto para inspecciones de vehículos?",
                "A": ["Con la IA de Inspektlabs, puede permitir que tanto su personal como sus clientes realicen inspecciones de vehículos de forma remota.", "Nuestro producto se puede acceder a través de una aplicación web, aplicación nativa, SDK o mediante una API integrada en su aplicación existente."]
            },
            {
                "Q": "¿Qué integraciones ofrece Inspektlabs?",
                "A": ["Las integraciones API sin interrupciones de Inspektlabs pueden conectarse directamente a sus flujos de trabajo donde podemos enviar una carga útil JSON a un webhook proporcionado por usted.", "Esta carga útil generalmente contiene las URL de los vehículos y las coordenadas de los daños."]
            },
            {
                "Q": "¿Inspektlabs soporta fotos y videos para inspecciones?",
                "A": ["Sí. La IA propietaria de Inspektlabs está entrenada para detectar daños utilizando tanto fotos como videos de los daños en el vehículo."]
            },
            {
                "Q": "¿Cómo maneja Inspektlabs los números VIN, las matrículas y las lecturas del odómetro?",
                "A": ["La IA de Inspektlabs está entrenada para identificar la información de identificación del vehículo, como el VIN y las matrículas, que luego se verifica para confirmar la propiedad del vehículo.", "Capturar la lectura del odómetro también es un paso obligatorio en el flujo de trabajo de inspección al utilizar la IA de Inspektlabs (Leer más)"]
            }
        ],
        "pt": [
            {
                "Q": "Você tem uma central de suporte para resolver problemas?",
                "A": ["Sim. Temos uma central de suporte 24x7 para resolver qualquer problema que você esteja enfrentando com nosso produto. Você pode abrir um ticket no JIRA ou nos enviar um e-mail diretamente para sinalizar seus problemas."]
            },
            {
                "Q": "A Inspektlabs pode se integrar com meus sistemas existentes?",
                "A": ["Sim. Oferecemos uma variedade de integrações API para integrar-se perfeitamente ao seu sistema e fluxo de trabalho existentes."]
            },
            {
                "Q": "Que tipo de peças a Inspektlabs cobre?",
                "A": ["A IA proprietária da Inspektlabs é treinada para detectar danos em 163 peças diferentes, incluindo peças interiores (ex: assentos) e exteriores (ex: faróis).", "Você pode ler mais detalhes sobre como facilitamos isso em nosso blog."]
            },
            {
                "Q": "Quem pode usar seu produto para inspeções de veículos?",
                "A": ["Com a IA da Inspektlabs, você pode permitir que tanto sua equipe quanto seus clientes realizem inspeções de veículos remotamente.", "Nosso produto pode ser acessado por meio de um aplicativo web, aplicativo nativo, SDK ou por meio de uma API integrada no seu aplicativo existente."]
            },
            {
                "Q": "Quais integrações a Inspektlabs oferece?",
                "A": ["As integrações API sem interrupções da Inspektlabs podem se conectar diretamente aos seus fluxos de trabalho, onde podemos enviar uma carga útil JSON para um webhook fornecido por você.", "Essa carga útil normalmente contém as URLs dos veículos e as coordenadas dos danos."]
            },
            {
                "Q": "A Inspektlabs suporta fotos e vídeos para inspeções?",
                "A": ["Sim. A IA proprietária da Inspektlabs é treinada para detectar danos usando tanto fotos quanto vídeos dos danos no veículo."]
            },
            {
                "Q": "Como a Inspektlabs lida com VIN, placas de veículos e leituras de odômetro?",
                "A": ["A IA da Inspektlabs é treinada para identificar informações de identificação do veículo, como VIN e placas de veículos, que depois são verificadas para confirmar a propriedade do veículo.", "Capturar a leitura do odômetro também é uma etapa obrigatória no fluxo de trabalho de inspeção ao usar a IA da Inspektlabs (Leia mais)"]
            }
        ]
    },
    "Fraud Detection": {
        "en": [
            {
                Q: "Do you have a support desk to fix issues?",
                A: ["Yes. We have a 24x7 support desk to address and fix any issues you are facing with our product. You can raise a JIRA ticket or directly email us to flag your issues."]
            },
            {
                Q: "Can Inspektlabs integrate with my existing systems?",
                A: ["Yes. We provide a variety of API integrations to seamlessly integrate into your existing system and workflow."]
            },
            {
                Q: "How does Inspektlabs help detect fraud?",
                A: ["Inspektlabs AI comes pre-built with various measures to help detect fraud.", "With Inspektlabs AI, you can keep track of vehicle info (VIN, license plate info), detect old vs. new damage, identify stickers, single vs. multi-point damage, and instances of vehicle switching.", "Additionally, we also collect data in real-time with metadata (GPS, timestamps etc). that verifies no tampering is done with the video."]
            },
            {
                Q: "How does Inspektlabs handle VIN, license plates, and odometer readings?",
                A: ["Inspektlabs AI is trained to identify vehicle identification information like VIN and license plates which is later verified to confirm vehicle ownership.", "Capturing the odometer reading is also a mandatory step in the inspection workflow while using Inspektlabs AI (Read more)"]
            }
        ],
        "es": [
            {
                "Q": "¿Tienen un servicio de soporte para solucionar problemas?",
                "A": ["Sí. Contamos con un servicio de soporte 24x7 para abordar y solucionar cualquier problema que esté enfrentando con nuestro producto. Puede crear un ticket en JIRA o enviarnos un correo electrónico directamente para señalar sus problemas."]
            },
            {
                "Q": "¿Puede Inspektlabs integrarse con mis sistemas existentes?",
                "A": ["Sí. Ofrecemos una variedad de integraciones API para integrarnos sin problemas en su sistema y flujo de trabajo existentes."]
            },
            {
                "Q": "¿Cómo ayuda Inspektlabs a detectar fraudes?",
                "A": ["La IA de Inspektlabs viene preconfigurada con diversas medidas para ayudar a detectar fraudes.", "Con la IA de Inspektlabs, puede realizar un seguimiento de la información del vehículo (VIN, matrícula), detectar daños antiguos vs. nuevos, identificar etiquetas, daños de un solo punto vs. múltiples puntos, y casos de cambio de vehículo.", "Además, también recopilamos datos en tiempo real con metadatos (GPS, marcas de tiempo, etc.) que verifican que no se ha alterado el video."]
            },
            {
                "Q": "¿Cómo maneja Inspektlabs los números VIN, las matrículas y las lecturas del odómetro?",
                "A": ["La IA de Inspektlabs está entrenada para identificar la información de identificación del vehículo, como el VIN y las matrículas, que luego se verifica para confirmar la propiedad del vehículo.", "Capturar la lectura del odómetro también es un paso obligatorio en el flujo de trabajo de inspección al utilizar la IA de Inspektlabs (Leer más)"]
            }
        ],
        "pt": [
            {
                "Q": "Você tem uma central de suporte para resolver problemas?",
                "A": ["Sim. Temos uma central de suporte 24x7 para resolver qualquer problema que você esteja enfrentando com nosso produto. Você pode abrir um ticket no JIRA ou nos enviar um e-mail diretamente para sinalizar seus problemas."]
            },
            {
                "Q": "A Inspektlabs pode se integrar com meus sistemas existentes?",
                "A": ["Sim. Oferecemos uma variedade de integrações API para integrar-se perfeitamente ao seu sistema e fluxo de trabalho existentes."]
            },
            {
                "Q": "Como a Inspektlabs ajuda a detectar fraudes?",
                "A": ["A IA da Inspektlabs vem pré-configurada com várias medidas para ajudar a detectar fraudes.", "Com a IA da Inspektlabs, você pode acompanhar as informações do veículo (VIN, placa do veículo), detectar danos antigos vs. novos, identificar adesivos, danos de um ponto único vs. múltiplos pontos e casos de troca de veículos.", "Além disso, também coletamos dados em tempo real com metadados (GPS, carimbos de data e hora, etc.) que verificam que o vídeo não foi adulterado."]
            },
            {
                "Q": "Como a Inspektlabs lida com VIN, placas de veículos e leituras de odômetro?",
                "A": ["A IA da Inspektlabs é treinada para identificar informações de identificação do veículo, como VIN e placas de veículos, que depois são verificadas para confirmar a propriedade do veículo.", "Capturar a leitura do odômetro também é uma etapa obrigatória no fluxo de trabalho de inspeção ao usar a IA da Inspektlabs (Leia mais)"]
            }
        ]
    }
}